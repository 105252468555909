

import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './pied-page.module.css';
import packageJSON from '../../../package.json';

function obtenirAnnee()
{
    return new Date().getFullYear();
}

export default function PiedPage() {
    return (
        <Row className={styles.conteneur_pied_page}>
            <Col lg="4" xs="0"></Col>
            <Col lg="4" xs="12" className="text-center">
                <Row>
                    <Col lg="6" xs="12">
                        <a className={styles.lien_bas_page} href="/politique-de-confidentialite">Politique de confidentialité</a>
                    </Col>
                    <Col lg="6" xs="12">
                        <a className={styles.lien_bas_page} href="/termes-et-conditions">Termes & Conditions</a>
                    </Col>
                    <Col lg="6" xs="12">
                        <a className={styles.lien_bas_page} href="/support-technique">Support technique</a>
                    </Col>
                    <Col lg="6" xs="12">
                        <a className={styles.lien_bas_page} href="/supprimer-compte">Demande de suppression de compte</a>
                    </Col>
                </Row>
                <p className={styles.texte_copyright}>Have Fun Solutions Corp. © {obtenirAnnee()} - Tous droits réservés. Site web réalisé par<br/> 
                    <a className={styles.lien_bas_page_gw} target="_blank" rel="noopener noreferrer" href="https://greenwoodmultimedia.com?utm_source=appliemotions.com&utm_medium=footer_link&utm_id=appliemotions">GreenWood Multimedia</a>.
                    Version { packageJSON.version }.
                </p>
            </Col>
            <Col lg="4" xs="0"></Col>
        </Row>
    );
  }