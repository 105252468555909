import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SousTitrePage from '../commun/sous-titre-page';
import styles from "./texte-terme-et-condition.module.css";
import TitrePage from '../commun/titre-page';

export default function TexteTermeEtCondition() {
    return (
        <Row className='text-justify'>
            <Col lg="4" xs="1"></Col>
            <Col lg="4" xs="10">
                <SousTitrePage titre_page="Introduction"></SousTitrePage>
                <p>Équipe TMS est une entreprise lancée en 2024 qui offre une application mobile intitulée « Émotions » qui est offerte au grand public pour divertissement et qui lui permet de découvrir des évènements du Québec.</p>
                <SousTitrePage titre_page="Objectif des termes & conditions"></SousTitrePage>
                <p>L’objectif des termes et conditions est d’établir des règles claires pour les utilisateurs, les annonceurs ou toute personne utilisant nos plateformes.</p>
                <SousTitrePage titre_page="Acceptation des termes et conditions"></SousTitrePage>
                <p>L’utilisation de l’application mobile Émotions implique une acceptation des termes et conditions. Sans cette autorisation, l’abonné ne peut se créer un compte et donc ne peut se servir de l’application mobile. </p>
                <p>Le mécanisme d’autorisation de votre part s’exécute simplement via le bouton ’’J’accepte’’. En cochant cette case, vous acceptez nos termes et conditions.</p>
                <SousTitrePage titre_page="Utilisation de l’application"></SousTitrePage>
                <p>L’application mobile Émotions présente les évènements à connaître dans votre région, ainsi qu’à plusieurs endroits dans la province du Québec. Plusieurs catégories de sorties seront présentes pour bien vous servir. Vous y trouverez de tout pour les intérêts de chacun.</p>
                <p>Les conditions de l’utilisation de l’application mobile Émotions sont pour usage personnel seulement. Il est interdit d’afficher une image illicite comme photo de profil.</p>
                <p>L’utilisateur ne possède aucun droit de pirater, ou contourner nos systèmes mis en place.</p>
                <p>La responsabilité de l’utilisateur est de respecter ces critères d’engagement envers notre produit.</p>
                <SousTitrePage titre_page="Compte utilisateur"></SousTitrePage>
                <p>Après avoir compléter le téléchargement de l’application mobile Émotions sur l’une des deux plates-formes Google Play ou via l’App Store. La première page qui s’affichera sera la création d’un compte. Vous devrez lire et accepter nos termes et conditions pour compléter le tout.</p>
                <p>Par nos programmes de sécurité mis en place, nous nous engageons à protéger votre courriel, votre mot de passe, votre identifiant, votre date de naissance, votre code postal (si transmis de votre part), votre photo de profil, et toutes informations personnelles à votre égard.</p>
                <p>Dans le but de protéger son compte, l’utilisateur doit conserver et ne pas divulguer son mot de passe. En cas de besoin, il sera possible de réinitialiser le mot de passe en cochant la case ’’Mot de passe oublié ?’’. Un courriel automatique sera envoyé à votre adresse courriel. À l’intérieur, il y aura un code d’accès créé pour vous de façon aléatoire.</p>
                <SousTitrePage titre_page="Propriété intellectuelle"></SousTitrePage>
                <p>Tous les droits de propriétés intellectuelle de l’application mobile et de son contenu sont réservés. Les marques Em et Emotions, nos logos, nos images, nos contenus à l’intérieur sont protégés par le droit d’auteur.</p>
                <SousTitrePage titre_page="Protection des données personnelles "></SousTitrePage>
                <p>Nous nous engageons à protéger les données personnelles de chacun des utilisateurs. Nous utilisons une méthode d’encryptions utilisant la technologie SSL 256-bits lors des échanges entre nos plates-formes.</p>
                <p>Nous utilisons les dernières pratiques en matière de cybersécurité afin de garantir la vie privée de nos utilisateurs. La collecte des données personnelles par l’application mobile est à des fins professionnelles dans le but d’améliorer les services. Pour plus de renseignements, consultez notre <a className={styles.lien_texte} href="/politique-de-confidentialite">politique de confidentialité</a>.</p>
                <SousTitrePage titre_page="Responsabilités et garanties"></SousTitrePage>
                <p>L’application mobile Émotions est en aucun cas responsable de tous programmes d’évènements qui changeraient de date, d’heure, ou pour tout autre raison, une fois inscrit à l’intérieur de la plate-forme. Il se peut que des choix d’activités soit annulés ou reportés à cause de la météo ou même à la discrétion des annonceurs. Nous proposons une vaste gamme d’activités et de sorties sans pour autant être responsable de tous changements ou d’annulations concernant celles-ci. Nous ne sommes nuls responsables en cas d’absence de billets ou de revente de ceux-ci pour aller voir l’évènement affiché dans notre plate-forme.</p>
                <p>Nous garantissons le service des annonces d’évènements pour la région de Montréal, Centre-du-Québec et la grande région de la ville de Québec. Nous vous informons également que nous prenons les dispositions nécessaires pour s’assurer que chacun des évènements affichés soit toujours à l’affiche au moment de la représentation, sans pour autant offrir une garantie avec certitude que l’évènement aura bel et bien lieu.</p>
                <SousTitrePage titre_page="Résiliation et suspensions de compte"></SousTitrePage>
                <p>L’utilisateur pourra fermer son compte dans l’application et les informations seront supprimées lorsque la requête de suppression de son compte sera traitée par notre serveur.</p>
                <p>Advenant le cas où un utilisateur perturberait le réseau, l’application mobile ou la plate-forme. Il en viendra de notre droit discrétionnaire de suspendre l’utilisateur à tous accès à nos services sans préavis et ce pour toutes raisons que nous jugeons pertinentes.</p>
                <SousTitrePage titre_page="Modifications des termes et conditions"></SousTitrePage>
                <p>Nous nous engageons à communiquer par courriel lors d’un changement de cette politique à nos utilisateurs.</p>
                <p>Nous nous engageons à communiquer via courriel lors d’un changement de cette politique à nos utilisateurs.</p>
                <p>L’utilisateur a le droit de refuser toutes modifications apportées à la politique des termes et conditions. La personne doit nous écrire par courriel à l’adresse suivante :</p>
                <a className={styles.lien_texte} href="mailto:info@appliemotions.com">info@appliemotions.com</a>
                <p>Dès la a suppression de son compte, l'utilisateur ne sera plus autorisé à profiter de l’application mobile sans devoir recréer un nouveau compte.</p>
                <SousTitrePage titre_page="Loi applicable et juridiction"></SousTitrePage>
                <p>Les lois applicables sont sur le territoire de la province du Québec. Nous nous conformons à toutes mesures nécessaires qui émanerait des lois provinciales et des lois fédérales du Canada.</p>
                <p>En cas de bris de sécurité ou de confidentialité concernant par exemple une fuite de données ou un vol d’informations, nous nous engageons à être transparent envers nos utilisateurs. Nous prendrons les mesures nécessaires pour remédier à la situation dans les plus brefs délais. </p>
                <p>Nous nous engageons à suivre toutes les Lois en vigueur et nous prendrons des mesures juridiques nécessaires si une situation de ce type est découverte dans nos plates-formes.</p>
                <SousTitrePage titre_page="Date d’entrée en vigueur des termes et conditions"></SousTitrePage>
                <p>2 avril 2024</p>
                <TitrePage titre_page="Coordonnées"></TitrePage>
                <SousTitrePage titre_page="Via courriel"></SousTitrePage>
                <a className={styles.lien_texte} href="mailto:info@appliemotions.com">info@appliemotions.com</a>
            </Col>
            <Col lg="4" xs="1"></Col>
        </Row>
    );
  }