import React from 'react'
import styles from './liste-avantage.module.css'
import { Card, Row, Col } from 'react-bootstrap';
import SousTitrePage from '../commun/titre-page';

export default function ListeAvantage() {
    return (
        <Row>
            <Col lg="2" xs="2"></Col>
            <Col lg="8" xs="8">
                <SousTitrePage titre_page="Les avantages"></SousTitrePage>
                <Card className={styles.carte}>
                    <Card.Body>
                        <Card.Title className={styles.titre_fonctionnalite}>Inflation ou pas, gratuit ce sera !</Card.Title>
                    </Card.Body>
                </Card>
                <Card className={styles.carte}>
                    <Card.Body>
                        <Card.Title className={styles.titre_fonctionnalite}>Aucune publicité.</Card.Title>
                    </Card.Body>
                </Card>
                <Card className={styles.carte}>
                    <Card.Body>
                        <Card.Title className={styles.titre_fonctionnalite}>Ne cherchez plus, les événements viennent à vous.</Card.Title>
                    </Card.Body>
                </Card>
                <Card className={styles.carte}>
                    <Card.Body>
                        <Card.Title className={styles.titre_fonctionnalite}>Tirage d'un prix festif à chaque mois.</Card.Title>
                    </Card.Body>
                </Card>
                <Card className={styles.carte}>
                    <Card.Body>
                        <Card.Title className={styles.titre_fonctionnalite}>Prévoyez des activités de couple !</Card.Title>
                    </Card.Body>
                </Card>
                <Card className={styles.carte}>
                    <Card.Body>
                        <Card.Title className={styles.titre_fonctionnalite}>Créez des moments mémorables !</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg="2" xs="2"></Col>
            <SousTitrePage titre_page="Types d'événements offerts"></SousTitrePage>
            <Col lg="2" xs="2"></Col>
            <Col lg="8" xs="10">
                <Row>
                    <Col lg="4" xs="10" className="text-center">
                        <Card className={styles.carte}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/guitariste-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Spectacles</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        
                        <Card className={styles.carte}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/dance-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Théâtre</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Card className={styles.carte}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/sports-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Sports</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Card className={styles.carte_etc}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/homme-binoculaire-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>À découvrir</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Card className={styles.carte}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/ville-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Municipal</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        
                        <Card className={styles.carte}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/gratuite-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Gratuités</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Card className={styles.carte}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/spiritualite-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Spiritualité</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Card className={styles.carte_etc}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/festival-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Festivals</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" xs="10" className="text-center">
                        <Card className={styles.carte}>
                            <Card.Img className={styles.image_categorie} variant="top" src="/images/icones/elephant-hf.png" />
                            <Card.Body>
                                <Card.Title className={styles.titre_fonctionnalite}>Thématiques</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
  }