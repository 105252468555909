import React from 'react'
import { Col } from 'react-bootstrap';
import styles from './navigation.module.css';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';

export default function Navigation() {
    return (
        <Row className={styles.conteneur_navigation_non_connecte}>
            <Col xs={12} className="text-center">
                <Link className={styles.hf_titre_logo} to="/">
                    <span className={styles.hf_titre_logo_icone}>Emotions</span>
                </Link>
            </Col>
        </Row>
    );
  }