import axios from "axios";
import validator from "validator";
import { CONSTANTS } from "../config/constants";

export function supprimer_compte(state, setState)
{
    if(validator.isEmpty(state.infosSuppression.code_reinitialisation))
    {
        setState({
            ...state,
            alerteSuppression: {
                type: "danger",
                texte: "Veuillez remplir le code de réinitialisation !"
            }
        })
        return;
    }

    axios.delete(
        CONSTANTS.nomDomaineApi + "/supprimer-compte",
        {
            data: state.infosSuppression
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    etapeSuppression: 3,
                    alerteSuppression: {
                        type: "success",
                        texte: "Votre compte a bel et bien été supprimé !"
                    }
                })
                return;
            }
            setState({
                alerteSuppression: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue !"
                }
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                alerteSuppression: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue !"
                }
            })
        })
}