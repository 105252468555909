import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SousTitrePage from '../commun/sous-titre-page';
import TitrePage from '../commun/titre-page';
import styles from "./texte-politique-confidentialite.module.css";

export default function TextePolitiqueConfidentialite() {
    return (
        <Row className='text-justify'>
            <Col lg="4" xs="1"></Col>
            <Col lg="4" xs="10">
                <SousTitrePage titre_page="Introduction"></SousTitrePage>
                <p>Équipe TMS S.E.N.C. est une entreprise lancée en 2024 qui offre une application mobile intitulée « Émotions » qui est offerte au grand public et qui permet de découvrir des événements du Québec.</p>
                <SousTitrePage titre_page="But de la politique de confidentialité"></SousTitrePage>
                <p>Le but de cette politique de confidentialité est d’informer les utilisateurs des informations collectées à leur égard et de les informer sur comment celles-ci seront utilisées par notre entreprise.</p>
                <SousTitrePage titre_page="Définition des termes clés utilisés dans le document"></SousTitrePage>
                <ul>
                    <li>« Application » : Représente l’application mobile Émotions publiée sur le Google Play Store et l’iOS Store.</li>
                    <li>« Site web » : Représente le site web officiel de l’application mobile Émotions.</li>
                    <li>« Informations utilisateur » : Informations personnelles reliées à l’utilisateur.</li>
                    <li>« Plateformes » : Tous systèmes permettant à l’application mobile Émotions de fonctionner.</li>
                    <li>« Plateformes web » : Site web relié à l’application mobile Émotions.</li>
                </ul>
                <SousTitrePage titre_page="Informations collectées"></SousTitrePage>
                <p>Lors de l’inscription d’un utilisateur à notre application, nous collectons des informations sur ce dernier. Voici la liste des informations dont l’utilisateur doit fournir pour s’inscrire :</p>
                <ul>
                    <li>Prénom;</li>
                    <li>Nom;</li>
                    <li>Date de naissance;</li>
                    <li>Courriel;</li>
                    <li>Mot de passe;</li>
                    <li>Code postal.</li>
                </ul>
                <SousTitrePage titre_page="Méthodes de collecte"></SousTitrePage>
                <p>L'unique méthode de collecte de ces informations s’effectuent à l’intérieur de l’application lors de l'inscription.</p>
                <SousTitrePage titre_page="Raisons de la collecte des informations"></SousTitrePage>
                <p>Nous collectons ces informations des utilisateurs afin de pouvoir les authentifier grâce à leurs courriels et leurs mots de passe.</p>
                <p>De plus, nous utilisons la date de naissance afin de s’assurer que tous nos utilisateurs ont 13 ans et de plus afin de respecter la Loi sur la publicité aux mineurs.</p>
                <p>De plus, nous pourrions utiliser l’âge afin de restreindre l’accès à certains événements destinés aux personnes majeures.</p>
                <p>Le courriel nous permet de communiquer à l’utilisateur des informations pertinentes par rapport à l’état de son compte.</p>
                <SousTitrePage titre_page="Utilisation des informations"></SousTitrePage>
                <p>Les informations des utilisateurs sont utilisées dans nos plateformes afin de fournir les services offerts par notre application et de personnaliser l’expérience de chacun de nos utilisateurs.</p>
                <SousTitrePage titre_page="Partage des informations"></SousTitrePage>
                <p>Aucune information des utilisateurs ne seront transmises à des tiers ou vendues en partie ou en totalité.</p>
                <p>Nous nous réservons le droit de créer des informations agrégées qui n’incluent pas les informations personnelles de l’utilisateur, mais pourrait contenir les informations suivantes :</p>
                <ul>
                    <li>Trois premiers chiffres du Code postal de l’utilisateur.</li>
                    <li>Événements ayant été vues par l’utilisateur.</li>
                    <li>Événements ayant été ajoutés dans l’agenda de l’utilisateur.</li>
                </ul>
                <p>Ces informations agrégées pourront être transmises ou vendues à nos clients afin de les informer sur les tendances du marché de l’événementiel.</p>
                <p>Nous nous réservons le droit également de partager les informations des utilisateurs si la Loi nous l’exige ou lors d’un mandat de perquisition de la Police.</p>
                <SousTitrePage titre_page="Conservation des informations"></SousTitrePage>
                <p>Nous conservons les informations des utilisateurs tout aussi longtemps que ceux-ci gardent leurs comptes ouverts avec nous.</p>
                <p>Toute information agrégée non identifiable cependant sera conservée et ce même si l’utilisateur ferme son compte.</p>
                <SousTitrePage titre_page="Politique de suppression des informations"></SousTitrePage>
                <p>L’utilisateur pourra fermer son compte dans l’application et les informations seront supprimées lorsque la requête de suppression de son compte sera traitée par notre serveur.</p>
                <SousTitrePage titre_page="Droits des utilisateurs"></SousTitrePage>
                <p>Tout utilisateur peut exercer un droit de rectification des données sur les informations de l’utilisateur qui le concerne. Dans l’application, toutes les informations de l’utilisateur sont modifiables.</p>
                <SousTitrePage titre_page="Droit d'accès aux informations personnelles"></SousTitrePage>
                <p>L’utilisateur peut en tout temps obtenir les informations utilisateur le concernant en visitant son profil dans l’application.</p>
                <SousTitrePage titre_page="Sécurité des informations"></SousTitrePage>
                <p>Nous utilisons une méthode d’encryption utilisant la technologie SSL 256-bits lors des échanges entre nos plateformes.</p>
                <p>Nous utilisons les dernières pratiques en matière de cybersécurité afin de garantir la vie privée de nos utilisateurs.</p>
                <SousTitrePage titre_page="Responsabilités en cas de violation de données"></SousTitrePage>
                <p>En cas de bris de sécurité, nous nous engageons à être transparent envers nos utilisateurs et de communiquer rapidement sur la situation sur nos réseaux sociaux.</p>
                <p>Nous nous engageons à suivre toutes les Lois en vigueur si une situation de ce type est découverte dans nos plateformes.</p>
                <SousTitrePage titre_page="Cookies et technologies similaires"></SousTitrePage>
                <p>Certaines de nos plateformes web utilisent Google Analytics qui est un outil de suivi permettant de savoir comment celle-ci est utilisée par les visiteurs.</p>
                <p>Cette technologie requiert l’ajout de cookie dans votre navigateur afin de pouvoir distinguer chaque utilisateur.</p>
                <p>Elle nous permet de comprendre et d’améliorer l’expérience de l’utilisateur sur nos plateformes web.</p>
                <p>Nous utilisons également Google Analytics Firebase pour analyser le comportement de nos utilisateurs dans notre application.</p>
                <SousTitrePage titre_page="Options de contrôle des cookies"></SousTitrePage>
                <p>Vous pouvez avec votre navigateur tel que Google Chrome bloquer l’enregistrement de ces cookies et ainsi, nous ne suivrons pas votre utilisation de notre plateforme web.</p>
                <SousTitrePage titre_page="Mises à jour de la politique de confidentialité"></SousTitrePage>
                <p>Nous nous réservons le droit de mettre à jour cette politique de confidentialité en tout temps. Elle sera mise à jour automatiquement sur notre plateforme web pour votre consultation.</p>
                <p>Nous nous engageons à communiquer via courriel lors d’un changement de cette politique à nos utilisateurs.</p>
                <SousTitrePage titre_page="Date de la dernière mise à jour"></SousTitrePage>
                <p>Mise à jour le 02 avril 2024.</p>
                <TitrePage titre_page="Coordonnées"></TitrePage>
                <p>Vous pouvez nous communiquer via les deux moyens suivants pour plus d'information.</p>
                <SousTitrePage titre_page="Via courriel"></SousTitrePage>
                <a className={styles.lien_texte} href="mailto:info@appliemotions.com">info@appliemotions.com</a>
            </Col>
            <Col lg="4" xs="1"></Col>
        </Row>
    );
  }