import React, { useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import Navigation from '../composants/commun/navigation';
import PiedPage from '../composants/commun/pied-page';
import ListeAvantage from '../composants/accueil/liste-avantage';
import ConclusionApplication from '../composants/accueil/conclusion-application';
import ReactGA from "react-ga4";
import ExplicationApplication from '../composants/accueil/explication-application';

export default function PageAccueil() {
  const [state] = useState({
    meta: {
      title: 'Application Emotions | Accueil',
      description: 'Emotions vous permet de découvrir des événements du Québec près de vous, grâce à une application mobile disponible sur Android et iOS.',
      canonical: 'https://appliemotions.com'
    } 
  });

  useEffect(() => {
    ReactGA.initialize("G-18846NY26L");
    ReactGA.send({ hitType: "pageview", page: "/", title: "Applications Emotions | Accueil" });
  }, [])

  return (
    <DocumentMeta {...state.meta}>
      <Navigation></Navigation>
      <ExplicationApplication></ExplicationApplication>
      <ListeAvantage></ListeAvantage>
      <ConclusionApplication></ConclusionApplication>
      <PiedPage></PiedPage>
    </DocumentMeta>
  );
}